.base-body {
  // scrollbar-color: #666 var(...); // only works in FF
  background-color: var(--body);
  color: var(--body-text);

  table:not(.colorized) th {
    background-color: var(--body-th);
    color: var(--body-th-text);
  }
  table.tr-border th {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--body-line);
  }
  td {
    color: var(--body-text);
  }

  input:not([type='checkbox'], [type='radio']),
  textarea,
  select {
    &:not(.plain) {
      background-color: var(--alpha-minimize05);
    }
    fill: var(--alpha-minimize05);
    color: var(--body-text);
    border: solid 2px transparent;
    &:hover {
      border-color: var(--body-line);
    }
    &:focus {
      border-color: var(--hilite);
      background-color: var(--body);
      fill: var(--body);
    }
  }

  .hilite {
    color: var(--hilite);
  }
  .bg-hilite,
  .bg-hilite > :is(td, th) {
    color: var(--hilite-text);
    background-color: var(--hilite);
    border-color: var(--hilite);
  }

  .bg-hilite,
  .bg-hilite-hover:hover {
    background-color: var(--hilite);
    color: var(--hilite-text);
  }

  .accent {
    color: var(--accent);
  }

  // note: do not make .bg-accent; use .base-accent instead
  .neutral,
  tr.neutral td {
    color: var(--neutral);
  }

  .bg-neutral,
  .bg-neutral-hover:hover {
    background-color: var(--neutral);
    color: var(--neutral-text);
  }
  .error {
    color: var(--error);
  }
  .warn {
    color: var(--warn);
  }
  a.minimize:not(:hover),
  .minimize {
    color: var(--body-minimize);
  }
  .alpha-minimize {
    color: var(--alpha-minimize25);
  }
  .bg-minimize {
    background-color: var(--body-minimize);
  }
  .b--minimize {
    border-color: var(--body-minimize);
  }
  .b--line {
    border-color: var(--body-line);
  }
  .b--hilite {
    border-color: var(--hilite);
  }
  .b--neutral {
    border-color: var(--neutral);
  }
  .b--accent {
    border-color: var(--accent);
  }
  .b--warn {
    border-color: var(--warn);
  }
  .b--error {
    border-color: var(--error);
  }
}

.base-accent {
  @extend .base-body;
  background-color: var(--accent);
  color: var(--accent-text);

  input:not([type='checkbox'], [type='radio']),
  textarea,
  select {
    background-color: var(--alpha-maximize05);
    fill: var(--alpha-maximize05);
    color: var(--accent-text);
    border: solid 2px transparent;
    &:hover {
      border-color: var(--accent-line);
    }
    &:focus {
      border-color: var(--hilite);
      background-color: var(--accent);
      fill: var(--accent);
    }
  }
}

.base-frame {
  @extend .base-body;
  background-color: var(--frame);
}

.base-navbar {
  &.navbar {
    position: fixed;
    height: var(--navbar-height);
  }
}

.base-error {
  background-color: var(--error);
  color: var(--error-text);
}
.base-warn {
  background-color: var(--warn);
  color: var(--warn-text);
}
.base-info {
  background-color: var(--info);
  color: var(--info-text);
  border-width: $border-width;
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: var(--info-border);
}
