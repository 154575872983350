.popups {
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  height: 3rem;
  z-index: 65535;
}
.popup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14pt;
  font-weight: 400;
  .close {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    border-radius: 0.5rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    @media screen and (min-width: 30em) {
      right: 1.5rem;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
