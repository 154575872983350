@import 'vars';

.heading {
  // text-transform: uppercase;
  font-weight: 600;
}

h1,
h2 {
  font-weight: 200;
}

h3,
h4 {
  font-weight: 700;
}
h3 {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

label {
  display: block;
  font-weight: 700;
}
