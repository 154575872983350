@import 'vars';

// general things
.theme-dark,
.theme-light .invert {
  --alpha-maximize05: rgba(0, 0, 0, 0.05);
  --alpha-maximize10: rgba(0, 0, 0, 0.1);
  --alpha-maximize15: rgba(0, 0, 0, 0.15);
  --alpha-maximize25: rgba(0, 0, 0, 0.25);
  --alpha-maximize50: rgba(0, 0, 0, 0.5);
  --alpha-maximize75: rgba(0, 0, 0, 0.75);
  --alpha-minimize05: rgba(255, 255, 255, 0.05);
  --alpha-minimize10: rgba(255, 255, 255, 0.1);
  --alpha-minimize15: rgba(255, 255, 255, 0.15);
  --alpha-minimize25: rgba(255, 255, 255, 0.25);
  --alpha-minimize50: rgba(255, 255, 255, 0.5);
  --alpha-minimize75: rgba(255, 255, 255, 0.75);
  --alpha-minimize85: rgba(255, 255, 255, 0.85);
}

.theme-light,
.theme-dark .invert {
  --alpha-minimize05: rgba(0, 0, 0, 0.05);
  --alpha-minimize10: rgba(0, 0, 0, 0.1);
  --alpha-minimize15: rgba(0, 0, 0, 0.15);
  --alpha-minimize25: rgba(0, 0, 0, 0.25);
  --alpha-minimize50: rgba(0, 0, 0, 0.5);
  --alpha-minimize75: rgba(0, 0, 0, 0.75);
  --alpha-maximize05: rgba(255, 255, 255, 0.05);
  --alpha-maximize10: rgba(255, 255, 255, 0.1);
  --alpha-maximize15: rgba(255, 255, 255, 0.15);
  --alpha-maximize25: rgba(255, 255, 255, 0.25);
  --alpha-maximize50: rgba(255, 255, 255, 0.5);
  --alpha-maximize75: rgba(255, 255, 255, 0.75);
  --alpha-maximize85: rgba(255, 255, 255, 0.85);
}

/*
todo:
 --frame to --modal?
*/

// common
// --body-error: #ff4136;
$error: #d8241b;
$error-text: #fff;
$warn: #ffb300;
$warn-text: #161b15;

$navbar-action-hover: #ffffff10;

$light-navbar: #161b15;
$light-body: #fff;
$light-body-text: #161b15;
$light-body-action: #32be23;
$light-body-action-hover: #51dc41;
$light-body-action-active: #46e7c8;
$light-body-minimize: #c4d4cf;
$light-body-line: #c4d4cf;
$light-info: #3c5956;
$light-info-text: #dfe7e5;
$light-info-border: #3c5956;
$light-neutral: #c4d4cf;
$light-neutral-action: #0aae00;
$light-neutral-hover: #cedfda;
$light-neutral-active: #12e2ab;
$light-neutral-text: #3c5956;
$light-neutral-text-hover: #557f7c;
$light-neutral-text-active: #fff;
$light-hilite: #12b2e2;
$light-accent: #3c6a5e;
$light-accent-text: #e9e9e9;
$light-accent-line: #c4d4cf;
$light-frame: #edf0ef;
$light-frame-hover: #f5f7f6;
$light-frame-inactive: #d9dbda;
$light-frame-inactive-text: #344034;
$light-th: #12e2ab;
$light-th-text: #3c5956;

$dark-navbar: #181a18;
$dark-body: #111311;
$dark-body: #111311;
$dark-body-text: #ddd;
$dark-body-action: #43ba37;
$dark-body-action-hover: #61ec61;
$dark-body-action-active: #12e2ab;
$dark-body-minimize: #465f4f;
$dark-body-line: #465f4f;
$dark-frame: #1c1f1c;
$dark-info: #c4d4cf;
$dark-info-text: #3c5956;
$dark-info-border: #c4d4cf;
$dark-neutral: #424845;
$dark-neutral-action: #{$dark-body-action};
$dark-neutral-hover: #{$dark-body-action-hover};
$dark-neutral-active: #{$dark-body-action-active};
$dark-neutral-text: #{$dark-body-text};
$dark-neutral-text-hover: #557f7c;
$dark-neutral-text-active: #fff;
$dark-accent: #397d79;
$dark-hilite: #0f93bb;
$dark-frame: #1b1d1b;
$dark-frame-hover: #262826;
$dark-frame-inactive: #232523;
$dark-frame-inactive-text: #939f9c;
$dark-th: #10a47c;
$dark-th-text: #053327;

// root is also default=>light; override as needed for dark/variants
:root {
  --navbar-height: 4rem;
  --font-family-body: 'Exo 2';
  --font-weight: 300;

  --breakpoint-small: #{$breakpoint-small};
  --breakpoint-medium: #{$breakpoint-medium};
  --breakpoint-large: #{$breakpoint-large};

  --error: #{$error};
  --error-text: #{$error-text};
  --warn: #{$warn};
  --warn-text: #{$warn-text};

  --navbar: #{$light-navbar};
  --navbar-text: #{$dark-body-text};
  --navbar-action: #{$dark-body-action};
  --navbar-line: #{$light-accent};
  --navbar-action-hover: #{$navbar-action-hover};
  --navbar-action-hover-text: #{$dark-body-action};
  --navbar-action-active: #{$dark-body-action-active};
  --navbar-action-active-text: #{$dark-body-action-active};

  --hilite-text: #fff;
  --hilite-action: #19f700;
  --hilite-action-hover: #15c8ff;
  --hilite-action-active: #51dc41;

  --accent-text: #{$light-accent-text};
  --accent-line: #{$light-accent-line};
}

.theme-dark .invert,
.theme-light,
.theme-corp {
  --navbar: #{$light-navbar};
  --body: #{$light-body};
  --body-text: #{$light-body-text};
  --body-action: #{$light-body-action};
  --body-action-text: #{$light-body};
  --body-action-hover: #{$light-body-action-hover};
  --body-action-active: #{$light-body-action-active};
  --body-th: #{$light-th};
  --body-th-text: #{$light-th-text};
  --body-minimize: #{$light-body-minimize};
  --body-line: #{$light-body-line};
  --accent: #{$light-accent};
  --hilite: #{$light-hilite};
  --info: #{$light-info};
  --info-text: #{$light-info-text};
  --info-border: #{$light-info-border};
  --neutral: #{$light-neutral};
  --neutral-action: #{$light-neutral-action};
  --neutral-hover: #{$light-neutral-hover};
  --neutral-active: #{$light-neutral-active};
  --neutral-text: #{$light-neutral-text};
  --neutral-text-hover: #{$light-neutral-text-hover};
  --neutral-text-active: #{$light-neutral-text-active};
  --frame: #{$light-frame};
  --frame-hover: #{$light-frame-hover};
  --frame-inactive: #{$light-frame-inactive};
  --frame-inactive-text: #{$light-frame-inactive-text};
}

.theme-dark,
.theme-light .invert,
.theme-corp .invert {
  --navbar: #{$dark-navbar};
  --body: #{$dark-body};
  --body-text: #{$dark-body-text};
  --body-action: #{$dark-body-action};
  --body-action-text: #{$dark-body};
  --body-action-hover: #{$dark-body-action-hover};
  --body-action-active: #{$dark-body-action-active};
  --body-th: #{$dark-th};
  --body-th-text: #{$dark-th-text};
  --body-minimize: #{$dark-body-minimize};
  --body-line: #{$dark-body-line};
  --accent: #{$dark-accent};
  --hilite: #{$dark-hilite};
  --info: #{$dark-info};
  --info-text: #{$dark-info-text};
  --info-border: #{$dark-info-border};
  --neutral: #{$dark-neutral};
  --neutral-action: #{$dark-neutral-action};
  --neutral-hover: #{$dark-neutral-hover};
  --neutral-active: #{$dark-neutral-active};
  --neutral-text: #{$dark-neutral-text};
  --neutral-text-hover: #{$dark-neutral-text-hover};
  --neutral-text-active: #{$dark-neutral-text-active};
  --frame: #{$dark-frame};
  --frame-hover: #{$dark-frame-hover};
  --frame-inactive: #{$dark-frame-inactive};
  --frame-inactive-text: #{$dark-frame-inactive-text};
}
