$light-neutral: #dfdfdf;
$dark-neutral: #333;

.theme-dark .blueprint .blueprint {
  &-cell {
    background-color: $dark-neutral;
    border: 1px solid $dark-neutral;
    &.partial,
    .partial {
      background: linear-gradient(to top right, black 48%, $dark-neutral 50%);
    }
    &.unselected:not(.cursor-not-allowed):hover,
    &.selected,
    .selected {
      background: #555;
    }
    &.set,
    .set {
      background: black;
    }
  }
  &-backdrop {
    background: black;
  }
}

.theme-light .blueprint .blueprint {
  &-cell {
    background-color: $light-neutral;
    border: 1px solid $light-neutral;
    &.partial,
    .partial {
      background: linear-gradient(to top right, white 48%, $light-neutral 50%);
    }
    &.unselected:not(.cursor-not-allowed):hover,
    &.selected,
    .selected {
      background: #ccc;
    }
    &.set,
    .set {
      background: white;
    }
  }
  &-backdrop {
    background: white;
  }
}
