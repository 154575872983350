@import 'vars';

* {
  box-sizing: border-box;
}
html:has(.theme-dark) {
  background-color: black;
}
body {
  overflow-y: scroll;
  overscroll-behavior: none;
  &::-webkit-scrollbar {
    background: var(--navbar);
  }
  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 7px;
    border: 3px solid var(--navbar);
  }
}
html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: var(--font-family-body), sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;

  :focus {
    outline: 0;
    box-shadow: none;
  }
  #body {
    margin-top: var(--navbar-height);
    flex: 1 0 auto;
  }
}
b,
strong {
  font-weight: 600;
}
p {
  margin-top: 1rem;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
}

select {
  cursor: context-menu;
}

svg text {
  font: bold 10pt 'Nunito';
  fill: var(--body-text);
}

.max-view-page {
  @media (min-width: #{$breakpoint-large}) {
    margin-left: auto;
    margin-right: auto;
    max-width: $breakpoint-large;
  }
}

// @mixin maxview($max-width) {
//   @media (min-width: 60rem) {
//     // @media screen and (min-width: var(90rem)) {
//     margin-left: auto;
//     margin-right: auto;
//     max-width: #{$max-width};
//   }
// }
//
//
// .max-view-page {
//   @include maxview(65rem);
// }
//
// @for $width from 1 through 8 {
//   $max-width: 60 + $width * 5;
//   .max-view-page-#{$max-width} {
//     @include maxview(#{$max-width}rem);
//   }
// }
