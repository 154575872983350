@import 'vars';

.base-body {
  .action,
  a:visited,
  a {
    color: var(--body-action);
    &.plain {
      color: var(--body-text);
    }
    &.neutral {
      color: var(--neutral-text-hover);
    }
    &:hover {
      color: var(--body-action-hover);
    }
    &:active {
      color: var(--body-action-active);
    }
    &.bg-hilite {
      color: var(--hilite-text);
      &:hover {
        background-color: var(--hilite-action-hover);
      }
      &:active {
        background-color: var(--hilite-action-active);
      }
    }
  }
  .bg-hilite a {
    color: var(--hilite-action);
  }
  .bg-neutral a {
    color: var(--neutral);
  }
  .menu:hover {
    background-color: var(--frame);
  }
  .menu-items {
    color: var(--body-text);
    background-color: var(--frame);
    border: solid 1px var(--alpha-minimize10);
    border-top: none;
  }
  a.menu-head,
  a.menu-item {
    color: var(--body-text);
  }
  a.menu-head,
  a.menu-item {
    &:hover {
      color: var(--body-text);
    }
  }
  .menu-item:hover {
    color: var(--body-text);
    background-color: var(--alpha-minimize10);
  }

  /*
  button variants/supported combos:

   action(default) — action BG
   neutral — grays
   clear — no BG, like body text, until you hover then it's a button
   border — inverted from action, with the action color as a border, and a solid BG
   border clear — same as border, but with a clear/transparent BG
   card — "action card" styled. Similar to action, but with the expectation of
          text and things. Maybe not necessary as a separate class.
   hilite

  With add-ons:

   + danger — when hovering add more scare to it
   + disabled
   + medium
   + large

  a, button {
    display: inline-block;
  }

  // can we just use one class?
  button,
  .button,
  a.button,
  button:visited,
  .button:visited,
  a.button:visited {
  */

  button,
  // try not to use <div> — this is for edge cases such as using react-select
  // that wants divs, but we still want it styled like our buttons
  div.button,
  a.button {
    //////////////////////////////////////////////////////////////////////////////
    // ACTION is default styling
    &:not(.unborder) {
      border-width: $border-width;
      border-radius: $rounding-radius;
    }

    border-style: solid;
    border-color: var(--body-action);
    background-color: var(--body-action);
    color: var(--body-action-text);

    &:hover {
      background-color: var(--body-action-hover);
      border-color: var(--body-action-hover);
      color: var(--body-action-text);
    }
    &:active {
      background-color: var(--body-action-active);
      border-color: var(--body-action-active);
      color: var(--body-action-text);
    }
    &:disabled,
    &.disabled {
      background-color: var(--neutral);
      border-color: var(--neutral);
      color: var(--neutral-text);
      cursor: not-allowed;
    }

    //////////////////////////////////////////////////////////////////////////////
    // NEUTRAL is grays and things instead of action colored
    &.neutral {
      color: var(--neutral-text);
      background-color: var(--neutral);
      border-color: var(--neutral);
      &:hover {
        background-color: var(--neutral-hover);
        border-color: var(--neutral-hover);
      }
      &:active {
        background-color: var(--neutral-active);
        border-color: var(--neutral-active);
      }

      // // note: you can't disable neutral buttons
      // &:disabled, &.disabled { color: }
    }

    //////////////////////////////////////////////////////////////////////////////
    // CLEAR is transparent BG until hovered, and then is like neutral
    &.clear {
      color: inherit;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        background-color: var(--neutral-hover);
        color: var(--neutral-text);
        border-color: var(--neutral-hover);
      }
      &:active {
        background-color: var(--neutral-active);
        color: var(--neutral-text);
        border-color: var(--neutral-active);
      }
    }

    //////////////////////////////////////////////////////////////////////////////
    // BORDER is inverted from action, with a flat background
    &.border {
      border: solid $border-width;
      background-color: var(--body);
      color: var(--body-text);
      border-color: var(--body-text);
      &.clear {
        background-color: transparent;
      }
      &.action {
        border-color: var(--body-action);
      }
      &:hover {
        color: var(--body-action-hover);
        border-color: var(--body-action-hover);
      }
      &:active {
        color: var(--body-action-active);
        border-color: var(--body-action-active);
      }
      &:disabled,
      &.disabled {
        color: var(--neutral);
        border-color: var(--neutral);
      }
    }

    &.danger:hover {
      background-color: var(--error);
      color: var(--error-text);
    }

    &.medium {
      padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    }
    &.large {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }

    &.hilite {
      color: var(--hilite-text);
      background-color: var(--hilite);
      border-color: var(--hilite);
      &:hover {
        background-color: var(--hilite-action-hover);
      }
      &:active {
        background-color: var(--hilite-action-active);
      }
    }
  }
}

.base-frame {
  button,
  a.button {
    &.border {
      background-color: var(--frame);
    }
  }
}

.base-accent {
  button,
  a.button {
    &.border {
      color: var(--accent-text);
      border-color: var(--accent-text);
      background-color: var(--accent);
    }
  }
}

a.bg-neutral {
  color: var(--neutral-text);
  &:hover {
    color: var(--neutral-text-hover);
    background-color: var(--neutral-hover);
  }
  &:active {
    color: var(--neutral-text-active);
    background-color: var(--neutral-active);
  }
}

.navbar-offset {
  margin-top: var(--navbar-height);
}

.base-navbar {
  background-color: var(--navbar);
  color: var(--navbar-text);
  a {
    color: var(--navbar-text);
    border: solid 1px;
    border-color: transparent;
    border-radius: $rounding-radius;
    &.border {
      border-color: var(--navbar-text);
    }
    // was .active; nav links aren't colorized unless it's the current page
    &.current {
      color: var(--navbar-action);
    }
    &.plain {
      color: var(--navbar-text);
    }
    &:active {
      color: var(--navbar-action-active);
      &.border {
        border-color: var(--navbar-action-active);
      }
    }
  }
  .menu {
    .menu-items {
      color: var(--navbar-text);
      background-color: var(--navbar);
      border: solid 1px var(--alpha-minimize15);
    }
    a.menu-item {
      color: var(--navbar-text);
    }
    a.menu-item:hover {
      color: var(--navbar-text);
      background-color: var(--alpha-maximize10);
    }
  }
}

// meant to connect with either button or accent
.card {
  border: none !important; // solid 2px transparent;
  border-radius: 0 $card-radius !important;
  position: relative;
  .auth-key {
    position: absolute;
    top: 1rem;
    right: 1rem;
    transform: scale(-1, 1);
  }
}
